
/* ---------------------------------- */
/* Photo Box
------------------------------------- */

.photo-box {
  border-radius: $global-border-radius;
  height: 360px;
  display: block;
  position: relative;
  background-size: cover;
  background-position: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  margin: 0 0 25px 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #333;
    opacity: 0.5;
    border-radius: $global-border-radius;
    transition: 0.4s;
  }
}

.photo-box-content {
  position: absolute;
  bottom: 30px;
  left: 30px;
  h3 {
    color: #fff;
    font-size: 20px;
    line-height: 28px;
  }
  span {
    color: $body-heading-color;
    background: #fff;
    border-radius: $global-border-radius;
    font-size: 15px;
    font-weight: 500;
    padding: 4px 10px;
    line-height: 20px;
    margin-top: 8px;
    display: inline-block;
  }
}

/* Hover State */

.photo-box {
  &:hover {
    box-shadow: 0 4px 12px rgba($primary-color, 0.25);
    transform: translateY(-10px);
    &:before {
      background: $primary-color;
      opacity: 0.95;
    }
  }
  &.small {
    height: 160px;
    margin-bottom: 30px;
    &:hover {
      transform: translateY(-5px);
    }
    .photo-box-content {
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      bottom: auto;
      top: 50%;
      transform: translateY(-49%);
      h3 {
        font-size: $body-font-size;
        font-weight: 600;
      }
      span {
        color: #fff;
        background: $primary-color;
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
        transition: 0.3s;
      }
    }
    &:hover .photo-box-content span {
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

/* Small Photobox Styles */