/*
==============================================================================================================================================
	Reset
==============================================================================================================================================
*/
ul, ol {
  padding: 0;
  margin: 0;
}

ul li {
  &.leaf, &.expanded {
    list-style-image: none;
    list-style-type: none;
  }
  &.leaf {
    list-style-type: none;
    list-style-image: none;
  }
}

.item-list ul li {
  list-style-type: none;
  list-style-image: none;
}

ul.menu li { margin: 0; }

li { list-style-position: inside; }

.item-list ul {
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
}

div.messages ul {
  margin: 0;
  padding: 0;
}

strong { color: inherit; }

nav { z-index: 100; }

.more { background: none; }

.clear { clear: both; }

html.js fieldset.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 3em;
}

.separateur-2border {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 4px;
}


a:hover, a:focus {
  text-decoration: none;
  outline:0;
}

.cursorable{
  cursor: pointer;
}

/*
==============================================================================================================================================
	CSS3 Colors Effects
==============================================================================================================================================
*/
.saturate {-webkit-filter: saturate(3); filter: saturate(3);}
.grayscale {-webkit-filter: grayscale(100%); filter: grayscale(100%);}
.contrast {-webkit-filter: contrast(160%); filter: contrast(160%);}
.brightness {-webkit-filter: brightness(0.25); filter: brightness(0.25);}
.blur {-webkit-filter: blur(3px); filter: blur(3px);}
.invert {-webkit-filter: invert(100%); filter: invert(100%);}
.sepia {-webkit-filter: sepia(100%); filter: sepia(100%);}
.huerotate {-webkit-filter: hue-rotate(180deg); filter: hue-rotate(180deg);}

/*
==============================================================================================================================================
	typographie
==============================================================================================================================================
*/

.c { text-align: center; }

.r { text-align: right; }

.l { text-align: left; }

.up { text-transform: uppercase; }

.b { font-weight: bold; }

/*
==============================================================================================================================================
	structure
==============================================================================================================================================
*/

.inline-block{
  display:inline-block;
}

/*
==============================================================================================================================================
	z-index
==============================================================================================================================================
*/
.zi2000{
  z-index:2000;
}

/*
==============================================================================================================================================
	padding, margin
==============================================================================================================================================
*/
.p-0 { padding: 0; }
.p-5 { padding: 5px; }
.p-10 { padding: 10px; }
.p-15 { padding: 15px; }
.p-20 { padding: 20px; }
.p-40 { padding: 40px; }
.p-50 { padding: 50px; }
.p-100 { padding: 100px; }
.p-150 { padding: 150px; }
.p-175 { padding: 175px; }
.p-200 { padding: 200px; }
.p-300 { padding: 300px; }

.p-b-0 { padding-bottom: 0; }
.p-b-10 { padding-bottom: 10px; }
.p-b-15 { padding-bottom: 15px; }
.p-b-20 { padding-bottom: 20px; }
.p-b-40 { padding-bottom: 40px; }
.p-b-50 { padding-bottom: 50px; }
.p-b-100 { padding-bottom: 100px; }
.p-b-150 { padding-bottom: 150px; }
.p-b-175 { padding-bottom: 175px; }
.p-b-200 { padding-bottom: 200px; }
.p-b-300 { padding-bottom: 300px; }

.p-t-0 { padding-top: 0; }
.p-t-10 { padding-top: 10px; }
.p-t-15 { padding-top: 15px; }
.p-t-20 { padding-top: 20px; }
.p-t-40 { padding-top: 40px; }
.p-t-50 { padding-top: 50px; }
.p-t-100 { padding-top: 100px; }
.p-t-150 { padding-top: 150px; }
.p-t-175 { padding-top: 175px; }
.p-t-200 { padding-top: 200px; }
.p-t-300 { padding-top: 300px; }

.p-l-0 { padding-left: 0; }
.p-l-10 { padding-left: 10px; }
.p-l-15 { padding-left: 15px; }
.p-l-20 { padding-left: 20px; }
.p-l-40 { padding-left: 40px; }
.p-l-50 { padding-left: 50px; }
.p-l-100 { padding-left: 100px; }
.p-l-150 { padding-left: 150px; }
.p-l-175 { padding-left: 175px; }
.p-l-200 { padding-left: 200px; }
.p-l-300 { padding-left: 300px; }

.p-r-0 { padding-right: 0; }
.p-r-10 { padding-right: 10px; }
.p-r-15 { padding-right: 15px; }
.p-r-20 { padding-right: 20px; }
.p-r-40 { padding-right: 40px; }
.p-r-50 { padding-right: 50px; }
.p-r-100 { padding-right: 100px; }
.p-r-150 { padding-right: 150px; }
.p-r-175 { padding-right: 175px; }
.p-r-200 { padding-right: 200px; }
.p-r-300 { padding-right: 300px; }

.p15-40 { padding: 15px 40px 15px 40px; }
.p15-50 { padding: 15px 50px 15px 50px; }
.p15-70 { padding: 15px 70px 15px 70px; }

.m-t-0 { margin-top: 0; }
.m-t-10 { margin-top: 10px; }
.m-t-15 { margin-top: 15px; }
.m-t-20 { margin-top: 20px; }
.m-t-40 { margin-top: 40px; }
.m-t-50 { margin-top: 50px; }
.m-t-100 { margin-top: 100px; }
.m-t-200 { margin-top: 200px; }
.m-t-300 { margin-top: 300px; }

.m-b-0 { margin-bottom: 0; }
.m-b-10 { margin-bottom: 10px; }
.m-b-15 { margin-bottom: 15px; }
.m-b-20 { margin-bottom: 20px; }
.m-b-40 { margin-bottom: 40px; }
.m-b-50 { margin-bottom: 50px; }
.m-b-100 { margin-bottom: 100px; }
.m-b-200 { margin-bottom: 200px; }
.m-b-300 { margin-bottom: 300px; }

.m-r-0 { margin-right: 0; }
.m-r-10 { margin-right: 10px; }
.m-r-15 { margin-right: 15px; }
.m-r-20 { margin-right: 20px; }
.m-r-40 { margin-right: 40px; }
.m-r-50 { margin-right: 50px; }
.m-r-100 { margin-right: 100px; }
.m-r-200 { margin-right: 200px; }
.m-r-300 { margin-right: 300px; }

.m-l-0 { margin-left: 0; }
.m-l-10 { margin-left: 10px; }
.m-l-15 { margin-left: 15px; }
.m-l-20 { margin-left: 20px; }
.m-l-40 { margin-left: 40px; }
.m-l-50 { margin-left: 50px; }
.m-l-100 { margin-left: 100px; }
.m-l-200 { margin-left: 200px; }
.m-l-300 { margin-left: 300px; }

.m-0 { margin: 0; }
.m-10 { margin: 10px; }
.m-15 { margin: 15px; }
.m-20 { margin: 20px; }
.m-40 { margin: 40px; }
.m-50 { margin: 50px; }
.m-100 { margin: 100px; }
.m-200 { margin: 200px; }
.m-300 { margin: 300px; }

.w20p { width: 20%; margin:auto}
.w50p { width: 50%; margin:auto}
.w80p { width: 80%; margin:auto}
.w100p { width: 100%; }


/*
==============================================================================================================================================
	Full link
==============================================================================================================================================
*/
.full-link {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

/*
==============================================================================================================================================
	Contenus : video embed
==============================================================================================================================================
*/
.media_embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.youtubevideowrap {
  width:90%;
  max-width: 640px;
}

