/*
=========================================================================================================
                Général
=========================================================================================================
*/

body {
  font-family: 'Raleway', sans-serif !important;
  font-size: 14px;
  line-height: 29px;
  font-weight: 500;
  color: #999;
}

.checkbox input {
  display: block;
}

input[type="file"] {
  height:auto;
}

input[type="checkbox"] {
  width:auto;
  height:auto;
}


/*
=========================================================================================================
                Header
=========================================================================================================
*/

#logo {
  padding-top: 6px;
  a {
    top: 9%;
  }
  font-size: 24px;
  font-weight: 700;
  line-height: 2em;
  margin: 3px 30px 0 0;
  img {
    margin-right: 10px;
    height: 42px;
    width: auto;
    top: auto;
    position: relative;
    transform: none;
  }
  .titre1 {
    font-size: 1.4em;
    color: #7b7b7b;
  }
  .titre2 {
    font-size: 1.4em;
    font-weight: bold;
    color: #03a9f4;
  }
  .pointcom{
    font-size: 0.8em;
    color: #7b7b7b;
  }
}

.header-notifications-trigger span {
  position: absolute;
  display: inline-block;
  top: 30px;
  margin-left: -10px;
  font-weight: 700;
  height: 56px;
  width: 56px;
  line-height: 52px;
  text-align: center;
  color: #fff;
  font-size: 11px;
  background-color: #2a41e8;
  border-radius: 50%;
  position: relative;
  font-size: 2em;
}
.notification-avatar:after, .user-avatar:after {
  bottom: -5px;
}


/*
=========================================================================================================
                Entête
=========================================================================================================
*/

.entete-projet{
  background:url('../images/bg-entete.jpg');
}

.single-page-header #breadcrumbs {
  padding: 10px;
}

/*
=========================================================================================================
                Grille Catégories
=========================================================================================================
*/

.fun-fact {
  text-align: center;
  margin-bottom: 10px;
  margin-right: 0;
  padding: 10px 10px 20px 10px;

  &:hover{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
    background: #eee6;
    cursor:pointer;
  }
  .fun-fact-text {
    margin-right: 0;
    height: 57px;
    width: 100%;
  }

  span {
    font-size: 18px;
    color: #888;
    line-height: 12px;
    height: auto;
  }

  .fun-fact-icon {
    margin: auto;
  }

  &.active{
    .fun-fact-text span,.fun-fact-icon i{
      color: #b81b7f;
    }
  }
}

.fun-fact-text {
  flex: none;
}

/*
=========================================================================================================
                Footer
=========================================================================================================
*/

#footer {
  margin-top: 40px;
}
