
/* ---------------------------------- */
/* Job Listing - List Layout
------------------------------------- */

.job-listing {
  display: block;
  border-radius: $global-border-radius;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  margin-bottom: 35px;
  background-color: #fff;
  transition: 0.3s;
  position: relative;
  &:hover {
    box-shadow: 0 2px 18px rgba(0, 0, 0, 0.14);
    transform: translateY(-4px);
  }
}

.job-listing-details {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 35px;
  padding-bottom: 32px;
}

.job-listing {
  .job-listing-company-logo {
    flex: 1;
    max-width: 55px;
    margin-right: 25px;
    position: relative;
    top: 3px;
    img {
      border-radius: $global-border-radius;
      transform: translate3d(0, 0, 0);
    }
  }
  .job-listing-description {
    flex: 1;
    padding-top: 3px;
    p {
      margin: 15px 0 0 0;
      padding: 0;
      color: $body-font-color;
    }
  }
  h4.job-listing-company {
    font-size: $body-font-size;
    color: #808080;
  }
  h3.job-listing-title {
    font-size: 20px;
    color: $body-heading-color;
    line-height: 30px;
  }
  .job-listing-footer {
    background-color: #f9f9f9;
    padding: 20px 35px;
    border-radius: 0 0 $global-border-radius $global-border-radius;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin-right: 14px;
        color: #777;
        &:last-child {
          margin-right: 0;
        }
        i {
          &.icon-material-outline-location-on {
            margin-right: 0;
          }
          position: relative;
          top: 1px;
          margin-right: 3px;
          color: #777;
        }
      }
    }
  }
}

/* Media Queries for Default List Layout*/
@media (max-width: 768px) {
  .job-listing {
    .job-listing-company-logo {
      display: none;
    }
    .job-listing-title {
      padding-right: 10%;
    }
  }
}